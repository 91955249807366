import React, {useState, useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
// import BackgroundImage from 'gatsby-background-image'
// import * as styles from './banner.module.scss'

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

export default (props) => {
  const connorOffice = props.connorOffice
  const post = props.post

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [jobTitle] = useState(post.title);
  const [jobLocation] = useState(post.displayLocation);
  const [jobURL] = useState('http://careers.connorgroup.com' + post.uri);
  const [attachment, setAttachment] = useState({});
  const [feedback, setFeedback] = useState('');
  const [resumeRequired, setResumeRequired] = useState(post.acfJobListing.resumeRequired);
  const [hearAboutUs, setHearAboutUs] = useState('');
  const [whosePost, setWhosePost] = useState('');
  const [associateName, setAssociateName] = useState('');
  const [pleaseExplain, setPleaseExplain] = useState('');
  const onDrop = useCallback(acceptedFiles => {

    setAttachment(acceptedFiles[0]);
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const validateForm = data => {
    let isValid = true
    console.log(data);
    if( data.firstName == "" ) {
      isValid = false
    }
    if( data.lastName == "" ) {
      isValid = false
    }
    if( data.email == "" ) {
      isValid = false
    }
    if( data.phone == "" ) {
      isValid = false
    }

    if( resumeRequired && data.attachment.name == undefined ) {
      isValid = false
    }
    return (isValid)
  }

  const handleChange = e => {
    if (e.target.name=="firstName") {
      setFirstName(e.target.value)
    }
    if (e.target.name=="lastName") {
      setLastName(e.target.value)
    }
    if (e.target.name=="email") {
      setEmail(e.target.value)
    }
    if (e.target.name=="phone") {
      setPhone(e.target.value)
    }
    if (e.target.name=="whose-post") {
      setWhosePost(e.target.value)
    }
    if (e.target.name=="associate-name") {
      setAssociateName(e.target.value)
    }
    if (e.target.name=="please-explain") {
      setPleaseExplain(e.target.value)
    }
  };

  const handleAttachment = e => {
    setAttachment(e.target.files[0])
  };

  const handleSubmit = e => {
    e.preventDefault();

    const form = e.target
    const data = {
      "form-name": form.getAttribute("name"),
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "phone": phone,
      "attachment": attachment,
      "job-title": post.title,
      "location": post.displayLocation,
      "url": jobURL,
      "hear-about-us": hearAboutUs,
      "whose-post": whosePost,
      "associate-name": associateName,
      "please-explain": pleaseExplain,
    }

    if ( validateForm(data) ) {
      fetch("/", {
        method: "POST",
        body: encode(data)
      })
        .then(() => {
          setFeedback('Thank you for your application! A member of our recruiting team will be in contact with you shortly.')
          setFirstName('')
          setLastName('')
          setEmail('')
          setPhone('')
          setHearAboutUs('')
          setWhosePost('')
          setAssociateName('')
          setPleaseExplain('')
          setAttachment({})
        })
        .catch(error => {
          console.log('An error occurred. Please try again.')
          console.log(error)
          setFeedback('An error occurred. Please try again.')
        })
    } else {
      setFeedback('Please complete required fields.')
    }
  };

  const handleCondition = e => {
    if (e.target.name=="hear-about-us") {
      setHearAboutUs(e.target.value)
    }

    if(e.target.value=="LinkedIn" || e.target.value=="Facebook" || e.target.value=="Instagram" || e.target.value=="Twitter") {
      var popup = document.getElementById("social");
      var other1 = document.getElementById("associate");
      var other2 = document.getElementById("explain");

      if (popup.style.display === "none") {
        popup.style.setProperty('display', 'block', 'important');
        other1.style.setProperty('display', 'none', 'important');
        other2.style.setProperty('display', 'none', 'important');
      }
    }

    if(e.target.value=="Indeed") {
      var popup = document.getElementById("social");
      var other1 = document.getElementById("associate");
      var other2 = document.getElementById("explain");

      popup.style.setProperty('display', 'none', 'important');
      other1.style.setProperty('display', 'none', 'important');
      other2.style.setProperty('display', 'none', 'important');
    }

    if(e.target.value=="Connor Group Associate") {
      var popup = document.getElementById("associate");
      var other1 = document.getElementById("social");
      var other2 = document.getElementById("explain");

      if (popup.style.display === "none") {
        popup.style.setProperty('display', 'block', 'important');
        other1.style.setProperty('display', 'none', 'important');
        other2.style.setProperty('display', 'none', 'important');
      }
    }

    if(e.target.value=="Other") {
      var popup = document.getElementById("explain");
      var other1 = document.getElementById("associate");
      var other2 = document.getElementById("social");

      if (popup.style.display === "none") {
        popup.style.setProperty('display', 'block', 'important');
        other1.style.setProperty('display', 'none', 'important');
        other2.style.setProperty('display', 'none', 'important');
      }
    }
  }

 

  return(
    <div id={props.id} style={{backgroundImage: "url(" + connorOffice + ")", backgroundSize: 'cover'}}>
      <div style={{background: "linear-gradient(90deg,rgba(0,156,101,0.9),rgba(0,156,101,0.9))"}} className="text-white" id="apply">
        <Container className="m-auto w-md-75 pb-5">
          <div className="text-center pt-5">
            <h2 className="text-uppercase mb-1">Application Form</h2>
            <h5 className="font-italic" style={{fontSize: "1rem", fontFamily: "Calendas"}}>Your new career begins here</h5>
          </div>
          <div className="overflow-hidden">
            <form
              name={post.acfJobListing.formRecipient ? post.acfJobListing.formRecipient : 'admin'}
              method="post"
              action="/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
            <input type="hidden" name="form-name" value={post.acfJobListing.formRecipient ? post.acfJobListing.formRecipient : 'admin'} />
            <input type="hidden" name="job-title" value={post.title} />
            <input type="hidden" name="location" value={post.displayLocation}/>
            <input type="hidden" name="url" value={ 'http://careers.connorgroup.com' + post.uri} />

            <Row>
                <Col>
                  <Form.Label className="text-uppercase">Name *</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control style={{borderRadius: "15px", padding: "1.5rem"}} name="firstName" type="text" placeholder="First Name" value={firstName} onChange={handleChange} />
                </Col>
                <Col>
                  <Form.Control style={{borderRadius: "15px", padding: "1.5rem"}} name="lastName" type="text" placeholder="Last Name" value={lastName} onChange={handleChange} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="text-uppercase pt-3">Phone Number *</Form.Label>
                </Col>
                <Col>
                  <Form.Label className="text-uppercase pt-3">Email Address *</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control style={{borderRadius: "15px", padding: "1.5rem"}} name="phone" type="text" placeholder="000-000-0000"  value={phone} onChange={handleChange} />
                </Col>
                <Col>
                  <Form.Control style={{borderRadius: "15px", padding: "1.5rem"}} name="email" type="email" placeholder="username@website.com"  value={email} onChange={handleChange} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="text-uppercase pt-3">How did you hear about us?</Form.Label>
                  <select id="hear-about-us" name="hear-about-us" className="form-control mb-2 mb-lg-0" style={{borderRadius: "15px", height: "50px"}} onChange={handleCondition}>
                      <option selected disabled>Select an option</option>
                      <option value="LinkedIn">LinkedIn</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Twitter">Twitter</option>
                      <option value="Indeed">Indeed</option>
                      <option value="Connor Group Associate">Connor Group Associate</option>
                      <option value="Other">Other</option>
                  </select>
                </Col>

                <Col id="social" style={{display: "none"}}>
                  <Form.Label className="text-uppercase pt-3">Whose post?</Form.Label>
                  <Form.Control style={{borderRadius: "15px", padding: "1.5rem"}} name="whose-post" type="text" value={whosePost} onChange={handleChange} />
                </Col>

                <Col id="associate" style={{display: "none"}}>
                  <Form.Label className="text-uppercase pt-3">Associate name?</Form.Label>
                  <Form.Control style={{borderRadius: "15px", padding: "1.5rem"}} name="associate-name" type="text" value={associateName} onChange={handleChange} />
                </Col>

                <Col id="explain" style={{display: "none"}}>
                  <Form.Label className="text-uppercase pt-3">Please explain:</Form.Label>
                  <Form.Control style={{borderRadius: "15px", padding: "1.5rem"}} name="please-explain" type="text" value={pleaseExplain} onChange={handleChange} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="text-uppercase pt-3">Resume { (resumeRequired) ? '*' : ''}</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div style={{borderRadius: "15px", height: "200px"}}  className="bg-white text-dark">

                  {

                      <div className="py-2 px-3" {...getRootProps()}>
                        <input name="attachment" {...getInputProps()} />
                        {
                          isDragActive ?
                            <div>Drop the file here</div> :
                            <div><u className="text-primary">Choose file</u> or drag here to upload.</div>
                        }
                        <div>{ attachment.name }</div>
                      </div>
                      }
                  </div>
                  <div className="text-right font-italic pt-1" style={{fontSize: ".8rem", fontFamily: "Calendas"}}>
                    * Required field
                  </div>
                  <div className="text-center">
                    <Button variant="outline-light" type="submit" style={{textTransform: "uppercase", borderRadius: "20px", padding: '0.5rem 5rem'}}>
                      Submit Form
                    </Button>
                    <div className="p-2">{ feedback }</div>
                  </div>
                </Col>
              </Row>

            </form>
          </div>
        </Container>
      </div>
    </div>
  )

}
