import React from 'react'
import {useState} from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { Parallax, Background } from 'react-parallax';
import MediaEmbed from '../components/media-embed'
import Image from '../components/image'

import logo from '../images/logo.svg'
import backgroundImage from '../images/anchor.jpg'
import iconPlay from '../images/icon-play.png'

export default (props) => {

  const jobListing = props.acfJobListing
  const keyHireBenefits = jobListing.keyHireBenefits

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  return (
    <section className="section section--dark section--shadowed">
      <Parallax strength={600}>
        <Background className="custom-bg">
          <img src={backgroundImage} style={{width: "100%"}} />
        </Background>
        <Container>
          <div className="section__content">
            <div className="mb-4">
              <h2 className="kh-h2 mb-4">Your career at</h2>
              <img src={logo} className="img-responsive" width="530" alt="The Connor Group" />
            </div>
        
            <h2 className="kh-h2 mb-4">{keyHireBenefits.headline}</h2>
        
            <Row>
              <Col lg={8}>
                <div dangerouslySetInnerHTML={{__html: keyHireBenefits.content}} />
              </Col>
              <Col lg={4}>
                <figure className="figure">
                  <Image {...keyHireBenefits.image.localFile.childImageSharp.fluid} alt={keyHireBenefits.image.altText} className="img-responsive" />
                  <figcaption className="figure__caption figure__caption--inset-top figure__caption--inset-left" dangerouslySetInnerHTML={{__html: keyHireBenefits.image.caption}} ></figcaption>
                </figure>
              </Col>
            </Row>
            <div className="photo-quote-cluster-1">
              <Image {...jobListing.message.image.localFile.childImageSharp.fluid} alt={jobListing.message.image.altText} className="photo-quote-cluster-1__image" />
              {/* <img src={jobListing.message.image.localFile.childImageSharp.resize.src} alt={jobListing.message.image.altText} className="photo-quote-cluster-1__image" /> */}
              <div className="photo-quote-cluster-1__content">
                <h4 className="text-center uppercase">
                  { jobListing.message.videoUrl ?
                    <button className="headline-button" onClick={handleShow}>
                      <img src={iconPlay} className="mr-2" />
                      {jobListing.message.headline}
                    </button>
                    :
                    <>{jobListing.message.headline}</>
                  }
                </h4>
                <blockquote className="photo-quote-cluster-1__quote kh-blockquote kh-blockquote--box">
                  <p>{jobListing.message.quote}</p>
                  <footer className="kh-blockquote__footer">
                    <span className="kh-blockquote__name">- {jobListing.message.quoteName}</span>
                    <span className="kh-blockquote__position">{jobListing.message.quotePosition}</span>
                  </footer>
                </blockquote>
        
                <div className="text-center">
                  <a href={jobListing.message.applyLink} className="btn btn-primary btn-rounded btn-lg uppercase">{jobListing.message.applyText}</a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Parallax>
      { jobListing.message.videoUrl &&
        <Modal
          show={show}
          onHide={handleClose}
          centered={true}
          size="xl" >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body >
            <MediaEmbed videoUrl={jobListing.message.videoUrl} autoplay={true} />
          </Modal.Body>
        </Modal>
      }
    </section>
  )
}