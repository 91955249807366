import { useStaticQuery, graphql } from 'gatsby'

export const usePage = () => {
  const { allWp } = useStaticQuery(
    graphql`
      query {
        allWp {
          nodes {
            growthContent {
              acfGrowthContent {
                history {
                  content
                  headline {
                    largeCopy
                    smallCopy
                  }
                  stats {
                    label
                    value
                  }
                  statsHeadline {
                    largeCopy
                    smallCopy
                  }
                }
                continuedGrowth {
                  headline
                  quote {
                    citation
                    content
                    photo {
                      altText
                      localFile {
                        childImageSharp {
                          id
                          fluid {
                            sizes
                            srcSet
                            src
                          }
                          resize {
                            src
                            tracedSVG
                            width
                            height
                            aspectRatio
                            originalName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            keyHireContent {
              acfIntroOptions {
                introHeadline
                introContent
              }
            }
          }
        }
      }
    `
  )
  return allWp.nodes[0];
}
