import React from 'react'
import { Container } from 'react-bootstrap'
import { Parallax, Background } from 'react-parallax';
import Slider from "react-slick";

import backgroundImage from '../images/connor-group-careers.jpg'

export default (props) => {

  const quote = props.quote

  const NextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <div
        className={className}
        style={{ ...style,
          display: 'flex',
          zIndex: '100',
          color: 'rgba(255,255,255,.7)',
        }}
        onClick={onClick}
      >
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg" style={{ fontSize: '25px',
        }} viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
      </div>
    );
  }

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style,
          display: 'flex',
          zIndex: '100',
          color: 'rgba(255,255,255,.7)',
        }}
        onClick={onClick}
      >
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" className="svg-inline--fa fa-chevron-left fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg" style={{ fontSize: '25px',
        }} viewBox="0 0 320 512"><path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>
      </div>
    );
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <section className="section section--dark section--overlay-green">
      <Parallax strength={600}>
        <Background className="custom-bg">
          <img src={backgroundImage} style={{width: "100%"}} />
        </Background>
        <Container>
          <div class="section__content">
            <h2 className="kh-h2 kh-h2--lg text-center">{props.acfJobListing.keyHireQuotes.quotesHeadline}</h2>
            <div className="blockquote-slider">
              <Slider {...sliderSettings}>
                {props.acfJobListing.keyHireQuotes.quotes.map((object) => (
                    <div>
                      <blockquote className="kh-blockquote kh-blockquote--flex blockquote-slider__slide">
                        <div className="kh-blockquote__content" dangerouslySetInnerHTML={{__html: object.content}}>
                        </div>
        
                        <footer className="kh-blockquote__footer">
                          <span className="kh-blockquote__name">- {object.acfQuotesOptions.name}</span>
                          <span className="kh-blockquote__position">{object.acfQuotesOptions.subtext}</span>
                        </footer>
                      </blockquote>
                    </div>
                  )
                )}
              </Slider>
            </div>
          </div>
        </Container>
      </Parallax>
    </section>
  )
}