import React from 'react'
import { Container } from 'react-bootstrap'
import { Parallax, Background } from 'react-parallax';

import backgroundImage from '../images/welcome.jpg'

export default (props) => {
  
  const content = props.content

  return (
    <section className="section section--dark section--parallax section--shadowed pt-0" style={{"--section-padding-top": "35vmin", "--section-min-height" : "80vh" }}>
      <Parallax strength={600}>
        <Background className="custom-bg">
          <img src={backgroundImage} style={{width: "100%"}} />
        </Background>
        <Container className="section__content">
          <div className="kh-welcome">
            <div className="kh-welcome__content">
              <h1 className="kh-h1">{content.introHeadline}</h1>
              <p className="p-large"
                dangerouslySetInnerHTML={{
                  __html: content.introContent.replace(
                    '{% job_title %}',
                    `<strong>${props.title}</strong>`
                  )
                }}
              >
              </p>
            </div>
          </div>
        </Container>
      </Parallax>
    </section>
  )
}