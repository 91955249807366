import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import iconEmail from '../images/icon-email.png'
import iconFacebook from '../images/icon-facebook.png'
import iconLinkedIn from '../images/icon-linkedin.png'

export default (props) => {
  return (
    <section className="section section--dark section--bg-image section--shadowed">
      <div className="section__inner">
        <Container>
          <h2 className="kh-h2 kh-h2--lg">{props.title}</h2>
          <Row>
            <Col lg={9}>
              <div dangerouslySetInnerHTML={{__html: props.content}} />
            </Col>
            <Col lg={3}>
              <div className="mb-4">
                <h3 className="kh-h3 mb-4">Job Details</h3>
                <p><strong>Job Title:</strong> {props.title}</p>
                <p><strong>Company:</strong> The Connor Group</p>
                <p><strong>Location:</strong> {props.displayLocation}</p>
                <p><strong>Position Type:</strong> {props.acfJobListing.positionType.name}</p>
              </div>
        
              <h3 className="kh-h3 mb-4">Share</h3>
              <div className="share-links mb-4">
                <a href={'mailto:?subject=' + encodeURIComponent(props.title + ' at The Connor Group') + '&body=' +
                      encodeURIComponent(props.title + ' at The Connor Group: https://careers.connorgroup.com' + props.uri) }>
                  <div className="sr-only">Share by Email</div>
                  <img src={iconEmail} alt="Email" />
                </a>
                <a href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent('https://careers.connorgroup.com' + props.uri)} target="_blank">
                  <div className="sr-only">Share on Facebook</div>
                  <img src={iconFacebook} alt="Facebook" />
                </a>
                <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent('https://careers.connorgroup.com' + props.uri)} target="_blank">
                  <div className="sr-only">Share on LinkedIn</div>
                  <img src={iconLinkedIn} alt="LinkedIn" />
                </a>
              </div>
              
              <a href="#apply" className="btn btn-primary btn-rounded btn-lg w-100 uppercase">Apply Now</a>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}