import React from 'react'
import {useState} from 'react'
import { Container, Modal } from 'react-bootstrap'
import Image from '../components/image'
import MediaEmbed from '../components/media-embed'

import iconPlay from '../images/icon-play.png'

export default (props) => {

  const employeeStory = props.acfJobListing.employeeStory

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <section className="section section--dark">
      <Container>
        <div className="section__content">
          <div className="photo-quote-cluster-2">
            <div className="photo-quote-cluster-2__col-1">
              <h4 className="uppercase text-center mb-2">
                {employeeStory.videoUrl ?
                  <button className="headline-button" onClick={handleShow}>
                    {employeeStory.headline}
                    <img src={iconPlay} className="ml-2" />
                  </button>
                 :
                 <>{employeeStory.headline}</>
                }
              </h4>
          
              <blockquote className="photo-quote-cluster-2__blockquote kh-blockquote kh-blockquote--box">
                <p>
                  {employeeStory.quote}
                </p>
                <footer className="kh-blockquote__footer">
                  <span className="kh-blockquote__name">- {employeeStory.quoteName}</span>
                  <span className="kh-blockquote__position">{employeeStory.quotePosition}</span>
                </footer>
              </blockquote>
            </div>
            <div className="photo-quote-cluster-2__col-2 text-center">
              <Image {...employeeStory.image.localFile.childImageSharp.fluid} alt={employeeStory.image.localFile.childImageSharp.altText} className="photo-quote-cluster-2__image img-responsive img-centered mb-4" />
              <div>
                <a href={employeeStory.applyLink} className="btn btn-primary btn-rounded btn-lg uppercase">{employeeStory.applyText}</a>
              </div>
            </div>
          </div>
        </div>
      </Container>
      { employeeStory.videoUrl &&
        <Modal 
          show={show} 
          onHide={handleClose}
          centered={true} 
          size="xl" >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body >
            <MediaEmbed videoUrl={employeeStory.videoUrl} autoplay={true} />
          </Modal.Body>
        </Modal>
      }
    </section>
  )
}