import React from 'react';
import { format, addDays } from 'date-fns'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//sections
import SectionQuotes from '../sections/quotes'
import SectionEmployeeStory from '../sections/employee-story'
import SectionHistory from '../sections/history'
import SectionKeyHireWelcome from '../sections/key-hire-welcome'
import SectionGrowth from '../sections/growth'
import SectionKeyHireJobDescription from '../sections/key-hire-job-description'
import SectionKeyHireYourCareer from '../sections/key-hire-your-career'

// components
import Header from "../components/header"
import Banner from "../components/banner"
import Footer from "../components/footer"
import SEO from "../components/seo"
import MediaEmbed from '../components/media-embed'
import ApplicationForm from '../components/application-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button } from 'react-bootstrap'
import * as styles from "./job-listing.module.scss"

// images
import culture from '../images/culture.png'
import compensation from '../images/compensation.png'
import women from '../images/women.png'
import plate2019 from '../images/plate2019.png'
import plate2020 from '../images/plate2020.png'

import grayLinesBG from '../images/background_stack.jpg'
import connorOffice from '../images/connoroffice.jpeg'

import { usePage } from "../hooks/key-hire-content"


const JobListing = ({ pageContext }) => {
  
  const post = pageContext
  const content = usePage()
  const keyHireContent = content.keyHireContent
  const growthContent = content.growthContent.acfGrowthContent
  const datePosted = new Date(post.date)
  const schema = {
    "@context": "http://schema.org",
    "@type": "JobPosting",
    "estimatedSalary": {
      "@type": "MonetaryAmount",
      "currency": "USD",
      "value": {
        "@type": "QuantitativeValue",
        "maxValue": post.acfJobListing.estimatedSalaryMaximum,
        "minValue": post.acfJobListing.estimatedSalaryMinimum,
        "unitText": post.acfJobListing.estimatedSalaryUnit
      }
    },
    "datePosted": format(datePosted, 'yyyy-MM-dd'),
    "description": post.content,
      "responsibilities": post.acfJobListing.responsibilities,
      "qualifications": post.acfJobListing.qualifications,
      "skills": post.acfJobListing.requirements,
      "validThrough": format(addDays(datePosted,30), 'yyyy-MM-dd'),
      "title": post.title,
      "employmentType": post.acfJobListing.employmentType,
      "jobBenefits": post.acfJobListing.benefits,
      "industry": "Real Estate",
      "hiringOrganization": {
          "@type": "Organization",
          "name": "The Connor Group",
          "sameAs": "https://connorgroup.com"
          },
      "jobLocation": {
          "@type": "Place",
          "address": {
              "@type": "PostalAddress",
              "addressLocality": post.acfJobListing.city,
              "addressRegion": post.acfJobListing.state,
              "postalCode": post.acfJobListing.zip,
              "addressCountry": "US"
          }
      },
      "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "USD",
          "value": {
              "@type": "QuantitativeValue",
              "maxValue": post.acfJobListing.baseSalaryMaximum,
              "minValue": post.acfJobListing.baseSalaryMinimum,
              "unitText": post.acfJobListing.baseSalaryUnit
          }
      }
  }

  let featuredImage = undefined
  if (post.featuredImage) {
    featuredImage = post.featuredImage.node
  }
  
  return !post.acfJobListing.isKeyHire ?
    // default job listing template
    <main>
        <SEO
          title={post.title} schemaMarkup={schema}
          image={ (featuredImage != undefined ) ? featuredImage.localFile.childImageSharp.fluid.src : null }
          />
        <Header />

        <Banner
          title={post.title}
          subtitle={post.displayLocation}
          backgroundImage={featuredImage}
        ></Banner>
        <div style={{backgroundImage: "url(" + grayLinesBG + ")", backgroundSize: '100%'}}>
          <div className={styles.positionContent} style={{position:'relative',marginTop:'-25vh'}}>
              <Container className="bg-white p-0">
                <div className="text-center py-4" style={{backgroundColor: '#1a302f'}}>
                  <Button href="#apply" variant="outline-light" className={styles.applyNow}>Apply Now</Button>
                </div>
                <Row className="p-4 m-0">
                <Col lg={6}>
                  <h2 style={{fontWeight: "300"}}>{post.title}</h2>
                  <h4 className="text-primary font-italic" style={{fontSize:'1rem', fontFamily: "Calendas", marginBottom: "1.5rem"}}>{post.acfJobListing.subtitle}</h4>
                  <hr />
                  <div dangerouslySetInnerHTML={{__html: post.content}} />
                </Col>
                <Col>
                  <Row>
                    <Col>
                    { post.acfJobListing.youtubeVideo && (
                      <MediaEmbed videoUrl={post.acfJobListing.youtubeVideo} />
                    )}
                    </Col>
                  </Row>
                  <Row className="pt-3 text-center text-md-left">
                    <Col md={6}>
                      <h3 className="text-uppercase font-weight-bold mb-3" style={{fontSize: "1.2rem"}}>Job Details:</h3>
                      <p className="mb-0">
                        <strong>Job Title:</strong> {post.title}
                      </p>
                      <p className="mb-0">
                        <strong>Company:</strong> The Connor Group
                      </p>
                      <p className="mb-0">
                        <strong>Location:</strong> {post.displayLocation}
                      </p>
                      <p>
                        <strong>Position Type:</strong> {post.acfJobListing.positionType.name}
                      </p>
                    </Col>
                    <Col md={6}>
                      <h3 className="text-uppercase font-weight-bold mb-3" style={{fontSize: "1.2rem"}}>Share:</h3>
                      <a href={'mailto:?subject=' + encodeURIComponent(post.title + ' at The Connor Group') + '&body=' +
                        encodeURIComponent(post.title + ' at The Connor Group: https://careers.connorgroup.com' + post.uri) }
                        className="d-inline-block px-1 text-white mr-1 text-center rounded"
                        style={{backgroundColor:'rgb(0,97,249)',height:'24px',width:'24px'}}>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </a>
                      <a href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent('https://careers.connorgroup.com' + post.uri)}
                        className="d-inline-block px-1 text-white mr-1 text-center rounded"
                        style={{backgroundColor:'rgb(0,112,235)',height:'24px',width:'24px'}} target="_blank">
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                      <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent('https://careers.connorgroup.com' + post.uri)}
                        className="d-inline-block px-1 text-white text-center rounded"
                        style={{backgroundColor:'rgb(0,114,169)',height:'24px',width:'24px'}} target="_blank">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {(post.acfJobListing.requirements) ? <div>
                        <h3>Some Job Requirements Include:</h3>
                        <div dangerouslySetInnerHTML={{__html: post.acfJobListing.requirements}} />
                      </div> : ''}
                      <div className="text-center mt-3">
                        <a href="#apply" className="text-uppercase" className={styles.applyNowSide} >Apply Now</a>
                      </div>
                      <Container className="pt-5">
                        <Row className="align-items-center mt-3 text-center">
                          <Col>
                            <img src={culture} alt="2019 Best Company Culture" className="w-100" />
                          </Col>
                          <Col>
                            <a href="https://www.entrepreneur.com/article/357740" target="_blank">
                              <img src={compensation} alt="2020 Best Company Compensation" className="w-100" />
                            </a>
                          </Col>
                          <Col>
                            <img src={women} alt="2019 Best Company for Women" className="w-100" />
                          </Col>
                        </Row>
                        <Row className="align-items-center mt-3 text-center">
                          <Col>
                            <img src={plate2019} alt="2019 Best Workplaces in Ohio" className="w-100" />
                          </Col>
                          <Col>
                            <img src={plate2020} alt="2020 Best Workplaces in Ohio" className="w-100" />
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <ApplicationForm
          id="apply"
          connorOffice={connorOffice} 
          post={post}
        />

        <Footer />
    </main>
  
  : // key hire job listing template
    <main className="kh-main">
      <SEO
        title={post.title} schemaMarkup={schema}
        image={ (featuredImage != undefined ) ? featuredImage.localFile.childImageSharp.fluid.src : null }
      />

      <Header />

      <SectionKeyHireWelcome { ... pageContext } content={keyHireContent.acfIntroOptions} />

      <SectionKeyHireJobDescription { ... pageContext } />

      <SectionKeyHireYourCareer {...pageContext} />

      <SectionHistory {...growthContent.history} />

      <SectionEmployeeStory { ...pageContext } />

      <SectionGrowth { ... growthContent.continuedGrowth } />

      <SectionQuotes { ...pageContext } />

      <ApplicationForm
        id="apply"
        connorOffice={connorOffice} 
        post={post}
      />

      <Footer />
    </main>
}

export default JobListing
